import React, { Component, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Categories = React.lazy(() => import('./views/categories/Categories'))
const Category = React.lazy(() => import('./views/categories/Category'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Login = React.lazy(() => import('./views/auth/Login'))
const Push = React.lazy(() => import('./views/push/EditCampaign'))
const Users = React.lazy(() => import('./views/users/Users'))
const User = React.lazy(() => import('./views/users/User'))
const Promotions = React.lazy(() => import('./views/promotions/Promotions'))
const Promotion = React.lazy(() => import('./views/promotions/Promotion'))
const Companies = React.lazy(() => import('./views/company/Companies'))
const Company = React.lazy(() => import('./views/company/Company'))
const Banners = React.lazy(() => import('./views/banners/Banners'))
const Banner = React.lazy(() => import('./views/banners/Banner'))
const Roles = React.lazy(() => import('./views/roles/Roles'))
const Role = React.lazy(() => import('./views/roles/Role'))
const News = React.lazy(() => import('./views/news/News'))
const EditNews = React.lazy(() => import('./views/news/EditNews'))
const Campaigns = React.lazy(() => import('./views/push/Campaigns'))
const EditCampaign = React.lazy(() => import('./views/push/EditCampaign'))
const TopBrand = React.lazy(() => import('./views/topbrands/EditTopBrands'))
const Parkings = React.lazy(() => import('./views/parking/Parkings'))
const Organizations = React.lazy(
  () => import('./views/organizations/Organizations'),
)
const Review = React.lazy(() => import('./views/dashboard/Review'))
const Orders = React.lazy(() => import('./views/orders/Orders'))
const Reports = React.lazy(() => import('./views/reports/Reports'))
const Menu = React.lazy(() => import('./views/menu/Menu'))
const Delivery = React.lazy(() => import('./views/delivery/Delivery'))
const CreateOrganizations = React.lazy(
  () => import('./views/organizations/CreateOrganizations'),
)

const App = (): JSX.Element => {
  function RequireAuth({ children }: any) {
    if (!localStorage.token) {
      return <Navigate to="/login" />
    }
    return children
  }

  return (
    <Router>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
            <Route
              path="/categories"
              element={
                <RequireAuth>
                  <Categories />
                </RequireAuth>
              }
            />
            <Route
              path="/categories/:id"
              element={
                <RequireAuth>
                  <Category />
                </RequireAuth>
              }
            />
            <Route
              path="/push"
              element={
                <RequireAuth>
                  <Push />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            {/* <Route
              path="/users/:id"
              element={
                <RequireAuth>
                  <User />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/promotions"
              element={
                <RequireAuth>
                  <Promotions />
                </RequireAuth>
              }
            />
            <Route
              path="/promotions/:id"
              element={
                <RequireAuth>
                  <Promotion />
                </RequireAuth>
              }
            />
            <Route
              path="/companies"
              element={
                <RequireAuth>
                  <Companies />
                </RequireAuth>
              }
            />
            <Route
              path="/companies/:id"
              element={
                <RequireAuth>
                  <Company />
                </RequireAuth>
              }
            />
            <Route
              path="/banners"
              element={
                <RequireAuth>
                  <Banners />
                </RequireAuth>
              }
            />
            <Route
              path="/banners/:id"
              element={
                <RequireAuth>
                  <Banner />
                </RequireAuth>
              }
            />
            {/* <Route
              path="/roles"
              element={
                <RequireAuth>
                  <Roles />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/roles/:id"
              element={
                <RequireAuth>
                  <Role />
                </RequireAuth>
              }
            />
            <Route
              path="/parkings"
              element={
                <RequireAuth>
                  <Parkings />
                </RequireAuth>
              }
            />
            <Route
              path="/news"
              element={
                <RequireAuth>
                  <News />
                </RequireAuth>
              }
            />
            <Route
              path="/news/:id"
              element={
                <RequireAuth>
                  <EditNews />
                </RequireAuth>
              }
            />
            <Route
              path="/campaigns"
              element={
                <RequireAuth>
                  <Campaigns />
                </RequireAuth>
              }
            />
            <Route
              path="/topbrands"
              element={
                <RequireAuth>
                  <TopBrand />
                </RequireAuth>
              }
            />
            <Route
              path="/campaigns/edit"
              element={
                <RequireAuth>
                  <EditCampaign />
                </RequireAuth>
              }
            />
            <Route
              path="/restaurants"
              element={
                <RequireAuth>
                  <Organizations key="1" />
                </RequireAuth>
              }
            />
            <Route
              path="/stores"
              element={
                <RequireAuth>
                  <Organizations key="2" />
                </RequireAuth>
              }
            />
            <Route
              path="/services"
              element={
                <RequireAuth>
                  <Organizations key="3" />
                </RequireAuth>
              }
            />
            <Route
              path="/ticket"
              element={
                <RequireAuth>
                  <Organizations key="4" />
                </RequireAuth>
              }
            />
            <Route
              path="/travels"
              element={
                <RequireAuth>
                  <Organizations key="5" />
                </RequireAuth>
              }
            />
            <Route
              path="/add-restaurant"
              element={
                <RequireAuth>
                  <CreateOrganizations key="1" />
                </RequireAuth>
              }
            />
            <Route
              path="/add-store"
              element={
                <RequireAuth>
                  <CreateOrganizations key="3" />
                </RequireAuth>
              }
            />
            <Route
              path="/add-service"
              element={
                <RequireAuth>
                  <CreateOrganizations key="2" />
                </RequireAuth>
              }
            />
            <Route
              path="/add-tickets"
              element={
                <RequireAuth>
                  <CreateOrganizations key="7" />
                </RequireAuth>
              }
            />
            <Route
              path="/add-travel"
              element={
                <RequireAuth>
                  <CreateOrganizations key="10" />
                </RequireAuth>
              }
            />
            <Route
              path="/restaurants/:organizationId"
              element={
                <RequireAuth>
                  <CreateOrganizations key="4" />
                </RequireAuth>
              }
            />

            <Route
              path="/stores/:organizationId"
              element={
                <RequireAuth>
                  <CreateOrganizations key="5" />
                </RequireAuth>
              }
            />

            <Route
              path="/services/:organizationId"
              element={
                <RequireAuth>
                  <CreateOrganizations key="6" />
                </RequireAuth>
              }
            />
            <Route
              path="/tickets/:organizationId"
              element={
                <RequireAuth>
                  <CreateOrganizations key="8" />
                </RequireAuth>
              }
            />
            <Route
              path="/travels/:organizationId"
              element={
                <RequireAuth>
                  <CreateOrganizations key="9" />
                </RequireAuth>
              }
            />
            <Route
              path="/review"
              element={
                <RequireAuth>
                  <Review />
                </RequireAuth>
              }
            />
            <Route
              path="/orders"
              element={
                <RequireAuth>
                  <Orders key="1" />
                </RequireAuth>
              }
            />
            <Route
              path="/history"
              element={
                <RequireAuth>
                  <Orders key="2" />
                </RequireAuth>
              }
            />
            <Route
              path="/reports"
              element={
                <RequireAuth>
                  <Reports />
                </RequireAuth>
              }
            />
            <Route
              path="/menu"
              element={
                <RequireAuth>
                  <Menu key="1" />
                </RequireAuth>
              }
            />
            <Route
              path="/products"
              element={
                <RequireAuth>
                  <Menu key="2" />
                </RequireAuth>
              }
            />
            <Route
              path="/service"
              element={
                <RequireAuth>
                  <Menu key="3" />
                </RequireAuth>
              }
            />
            <Route
              path="/tickets"
              element={
                <RequireAuth>
                  <Menu key="3" />
                </RequireAuth>
              }
            />
            <Route
              path="/travel"
              element={
                <RequireAuth>
                  <Menu key="4" />
                </RequireAuth>
              }
            />
            <Route
              path="/delivery"
              element={
                <RequireAuth>
                  <Delivery />
                </RequireAuth>
              }
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
